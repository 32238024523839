export const environment = {
  version: '1.1.2',
  production: false,
  environment: 'preprod',
  apiURL: 'https://api-preprod-v2.worldofsweets.co.uk/',
  apiVersion: 'v3',
  username: 'appuser@mailinator.com',
  password: 'Admin@123456',
  client_id: 'Qp3IFurNh9hKL9WcwSPTpDaKce1p6bwXrFGvFpjG',
  s3_path: 'http://shb-hubpix.s3.amazonaws.com/',
  client_secret: 'bZ3s1CEFYweEaLXiy9CIU7nFYkRwodeLfQ2o5JwqD4DeeL4nwRnbcKahwJhHW4aRGdaiVrHhmbOnrcV2iWPgrwbOA1oamAjlQZ6DFHnVsJRedLR4vkFh9BNckCkQVFeD',
  grant_type: 'password',
  address_lookup_key: 'YU99-UT62-RZ43-JH12',
  dropdown_limit: 100,
  hashedSecretKey:'f38ecf5379324f565d0365be0547b42e',
  hashedIV:'186DE986FC69F8E4'
};
